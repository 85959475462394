<template>
  <div>
    <h2 class="text-center">
      {{ $t('states') }}
    </h2>
    <v-row class="mx-2">
      <template v-for="(item, index) in states">
        <ChannelOrderState
          :key="index"
          :state-text="item.text"
          :state-key="item.key"
          :value="findOrderState(item.key)"
          v-on="$listeners"
        />
      </template>
    </v-row>
  </div>
</template>

<script>
	import ChannelOrderState from "@/components/channel/ChannelOrderState"
	export default {
		emit: ['input'],
		name: 'ChannelOrderStates',
		data() {
			return {
				states: [
					{
						text: this.$t('processing'),
						key: 'processing',
					},
					{
						text: this.$t('completed'),
						key: 'completed',
					},
					{
						text: this.$t('delivered'),
						key: 'delivered',
					},
					{
						text: this.$t('new'),
						key: 'new',
					}
				]
			}
		},
		components: {
			ChannelOrderState
		},
		props: {
			value: {
				type: Array,
				default: () => []
			},
		},
		computed: {
			orderStates: {
				get() {
					return this.value
				},
				set(value) {
					this.$emit('input', value)
				}
			}
		},
		methods: {
			findOrderState(orderState) {
				return this.orderStates.find((os) => os.orderState === orderState)
			}
		}
	}
</script>


