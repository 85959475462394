<template>
  <v-form>
    <v-text-field
      v-model="value.label"
      :label="$t('label')"
      outlined
    />
    <v-text-field
      v-model="value.description"
      :label="$t('description')"
      outlined
    />
    <v-switch
      v-model="showOrderStates"
      :label="$t('deliverSMSToCustomerOnOrderStateChanges')"
    />
    <v-switch
      v-model="showOrderStateNew"
      :label="$t('deliverSMSToCustomerOnOrderConfirmation')"
      :disabled="!showOrderStates"
    />
    <ChannelOrderStates
      v-show="showOrderStates"
      :key="value.notifyCustomerOnStateChanges.length"
      v-model="value.notifyCustomerOnStateChanges"
      v-on="$listeners"
    />
    <v-btn
      v-if="!loading"
      class="mt-4"
      outlined
      rounded
      @click="updateChannel"
      :disabled="!formValid()"
    >
      {{ $t('save') }}
    </v-btn>
    <v-btn
      v-else
      class="mt-4"
      outlined
      rounded
      loading
    />
  </v-form>
</template>

<script>
	import ChannelOrderStates from "@/components/channel/ChannelOrderStates"
	export default {
		emits: ['setChannelOrderStates', 'clearChannelOrderStates', 'removeChannelOrderState'],
		name: 'ChannelForm',
		props: {
			value: {
				type: Object,
				default: null
			}
		},
		components: {
			ChannelOrderStates
		},
		data() {
			return {
				showOrderStates: false,
				showOrderStateNew: false,
				loading: false
			}
		},
		computed: {
			channelUUID() {
				return this.value.uuid
			}
		},
		methods: {
			formValid() {
				return this.value && this.value.label
			},
			setOrderStatePropertiesAndValues() {
				if(this.value.deliverSMSToCustomerOnOrderStateChanges) {
					this.showOrderStates = this.value.deliverSMSToCustomerOnOrderStateChanges
				} else {
					this.value.deliverSMSToCustomerOnOrderStateChanges = false
					this.showOrderStates = false
				}

				if(this.value.deliverSMSToCustomerOnOrderConfirmation) {
					this.showOrderStateNew = this.value.deliverSMSToCustomerOnOrderConfirmation
				} else {
					this.value.deliverSMSToCustomerOnOrderConfirmation = false
					this.showOrderStateNew = false
				}
			},
			updateChannel() {
				if(this.formValid()) {
					this.loading = true
					this.$store.dispatch('updateChannel', this.value).then(res => {
						if(res) {
							this.$store.commit('updateActionSuccess', {
								message: this.$t('success'),
								subMessage: this.$t('channelUpdated'),
							})
						} else {
							this.$store.commit('updateActionError', {
								message: this.$t('error'),
								subMessage: this.$t('failedToUpdateChannel'),
							})
						}
					}).catch(() => {
						this.$store.commit('updateActionError', {
							message: this.$t('error'),
							subMessage: this.$t('failedToUpdateChannel'),
						})
					}).finally(() => {
						this.loading = false
					})
				}
			}
		},
		created() {
			 this.setOrderStatePropertiesAndValues()
		},
		watch: {
			channelUUID(current, previous) {
				if(current !== previous) {
					this.setOrderStatePropertiesAndValues()
				}
			},
			showOrderStates(current) {
				if(current) {
					this.value.deliverSMSToCustomerOnOrderStateChanges = true
					if(this.value.notifyCustomerOnStateChanges.length === 0) {
						this.$emit('setChannelOrderStates', [{orderState: 'processing', smsContent: ''}, {orderState: 'completed', smsContent: ''}, {orderState: 'delivered', smsContent: ''}])
					}
				} else {
					this.value.deliverSMSToCustomerOnOrderStateChanges = false
					this.$emit('clearChannelOrderStates')
					this.showOrderStateNew = false
				}
			},
			showOrderStateNew(current) {
				if(current) {
					this.value.deliverSMSToCustomerOnOrderConfirmation = true
					const newStateIsActive = this.value.notifyCustomerOnStateChanges.find(state => state.orderState === 'new')
					if(!newStateIsActive) {
						this.$emit('setChannelOrderStates', [{orderState: 'new', smsContent: ''}])
					}
				} else {
					this.value.deliverSMSToCustomerOnOrderConfirmation = false
						this.$emit('removeChannelOrderState', 'new')
				}
			}
		}
	}
</script>
