<template>
  <v-col
    cols="6"
  >
    <v-card
      outlined
      rounded="xl"
      class="order-state-sms-card"
    >
      <v-card-title>
        {{ stateText }}
        <v-spacer />
        <v-checkbox
          class="text-right"
          v-model="stateIsActive"
        />
      </v-card-title>

      <v-card
        outlined
        rounded="xl"
        class="order-state-sms-card"
      >
        <v-switch
          v-if="state"
          :label="$t('customSmsContent')"
          v-model="showCustomSmsContent"
          class="ml-4"
        />
        <h4
          v-else
          class="ma-5 grey--text"
        >
          {{ $t('disabled') }}
        </h4>
        <v-textarea
          v-if="showCustomSmsContent && stateIsActive"
          v-model="state.smsContent"
          outlined
          :label="$t('smsContent(defaultTextUsedIfEmpty)')"
          class="mx-5 "
          background-color="white"
        />
      </v-card>
    </v-card>
  </v-col>
</template>

<script>
export default {
	emits: ['setChannelOrderStates', 'removeChannelOrderState'],
	name: 'ChannelOrderState',
	props: {
		value: {
			type: Object,
			default: null
		},
		stateText: {
			type: String,
			default: ''
		},
		stateKey: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			state: this.value,
			stateIsActive: false,
			showCustomSmsContent: false
		}
	},
	created() {
		this.stateIsActive = Boolean(this.state)
		if(this.state && this.state.smsContent && this.state.smsContent.length > 0) {
			this.showCustomSmsContent = true
		}
	},
	watch: {
		stateIsActive() {
			if(this.stateIsActive && !this.state) {
				this.$emit('setChannelOrderStates', [{orderState: this.stateKey, smsContent: ''}])
			} else if (!this.stateIsActive && this.state){
				this.$emit('removeChannelOrderState', this.stateKey)
			}
		},
		showCustomSmsContent(current) {
			if(!current) {
				this.state.smsContent = ''
			}
		}
	}
}
</script>

<style scoped>

.order-state-sms-card{
		border-color: black;
		border-width: 1px;
		background-color: #f5f5f5;
}
</style>
